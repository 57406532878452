import React from 'react';
import { H2, BackIcon } from '../reusable-components';
import { RoundInformationWrapper } from './help_styles';
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive/src";
import NavBar from '../navbar';
import { useHistory } from "react-router-dom";
import { IsRightToLeftTheme } from '../theme/themehelper';

const Help = () => {

    const history = useHistory();
    const goBack = () => {
        history.goBack()
    }
    const { t } = useTranslation(['translation', 'rules']);

    let extraClass = "";

    if (IsRightToLeftTheme(window.theme)) {
        extraClass = "rtl";
    }

    return (
        <>
            <NavBar whichToUnderline="6" />
            <RoundInformationWrapper>
                <MediaQuery maxWidth={950}>
                    <div className="go-back">
                        <div className="go-back-button" id={'go-back-button'} onClick={() => goBack()}>
                            <div>{BackIcon("var(--text-black-dark-background)")}</div>
                            <div className={'bold'}>{t('go_back')}</div>
                        </div>
                    </div>
                </MediaQuery>

                <H2>{t('rules:faq.title')}</H2>
                <div className='flex-start'>
                    <div className='help-header'>{t('rules:faq.q1.question')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:faq.q1.answer')}</div>

                    <div className='help-header'>{t('rules:faq.q2.question')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:faq.q2.answer')}</div>

                    <div className='help-header'>{t('rules:faq.q3.question')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:faq.q3.answer')}</div>

                    <div className='help-header'>{t('rules:faq.q4.question')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:faq.q4.answer')}</div>

                    <div className='help-header'>{t('rules:faq.q5.question')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:faq.q5.answer')}</div>

                    <div className='help-header'>{t('rules:faq.q6.question')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:faq.q6.answer')}</div>
                </div>
                <H2>{t('rules:terms.title')}</H2>
                <div className='flex-start'>
                    <div className='help-header'>{t('rules:terms.p1.title')}</div>
                    <br />
                    <div className={"help-paragraph " + extraClass}>1.1</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p11')}</div>
                    <div className={"help-paragraph " + extraClass}>1.2</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p12')}</div>
                    <div className={"help-paragraph " + extraClass}>1.3</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p13')}</div>
                    <div className={"help-paragraph " + extraClass}>1.4</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p14')}</div>
                    <div className={"help-paragraph " + extraClass}>1.5</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p15')}</div>
                    <div className={"help-paragraph " + extraClass}>1.6</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p16')}</div>
                    <div className={"help-paragraph " + extraClass}>1.7</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p17')}</div>
                    <div className={"help-paragraph " + extraClass}>1.8</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p18')}</div>
                    <div className={"help-paragraph " + extraClass}>1.9</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p19')}</div>
                    <div className={"help-paragraph " + extraClass}>1.10</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p110')}</div>
                    <div className={"help-paragraph " + extraClass}>1.11</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p111')}</div>
                    <div className={"help-paragraph " + extraClass}>1.12</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p112')}</div>
                    <div className={"help-paragraph " + extraClass}>1.13</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p113')}</div>
                    <div className={"help-paragraph " + extraClass}>1.14</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p114')}</div>
                    <div className={"help-paragraph " + extraClass}>1.15</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p115')}</div>
                    <div className={"help-paragraph " + extraClass}>1.16</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p116a')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p116b')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p116c')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p116d')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p116e')}</div>
                    <div className={"help-paragraph " + extraClass}>1.17</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p117')}</div>
                    <div className={"help-paragraph " + extraClass}>1.18</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p118')}</div>
                    <div className={"help-paragraph " + extraClass}>1.19</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p119')}</div>
                    <div className={"help-paragraph " + extraClass}>1.20</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p120')}</div>
                    <div className={"help-paragraph " + extraClass}>1.21</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p1.p121')}</div>
                    <br />
                    <div className='help-header'>{t('rules:terms.p2.title')}</div>
                    <br />
                    <div className={"help-paragraph " + extraClass}>2.1</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p2.p21')}</div>
                    <div className={"help-paragraph " + extraClass}>2.2</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p2.p22')}</div>
                    <div className={"help-paragraph " + extraClass}>2.3</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p2.p23')}</div>
                    <div className={"help-paragraph " + extraClass}>2.4</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p2.p24')}</div>
                    <div className={"help-paragraph " + extraClass}>2.5</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p2.p25')}</div>
                    <div className={"help-paragraph " + extraClass}>2.6</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p2.p26')}</div>
                    <div className={"help-paragraph " + extraClass}>2.7</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p2.p27')}</div>
                    <div className={"help-paragraph " + extraClass}>{t('rules:terms.p2.amendment')}</div>
                </div>

            </RoundInformationWrapper>

        </>
    );
}

export default Help;