import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { KungShirtIcon } from "../../images/kungicons";
import { FixtureDetail } from "../../models/fixturedetail";
import { Fixture } from "../../models/fixture";
import { IsRightToLeftTheme, themeC } from "../../theme/themehelper";
import useColorThief, { FormatString } from 'use-color-thief';
import kungTShirt from '../../images/tshirt.png';
import { useTranslation } from "react-i18next";

function ScoreBoard({ fixtureDetail, roundFixture, theme }:
    { fixtureDetail: FixtureDetail, roundFixture: Fixture, theme: string }) {

    const home = useColorThief(roundFixture.homeTeam.logoUrl, { format: FormatString.hex })
    const away = useColorThief(roundFixture.awayTeam.logoUrl, { format: FormatString.hex })
    const { t } = useTranslation();

    let isRightToLeft = IsRightToLeftTheme(theme);

    let shirt = `url(${kungTShirt})`;

    const shirtContainerStyle: SxProps<Theme> = {
        background: shirt,
        width: 70, height: 70,
        backgroundSize: "cover",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 0.6,
        marginTop: "10px"
    }

    let scoreStyle: SxProps<Theme> = {
        whiteSpace: "nowrap",
        margin: "0px 0px 20px 0px",
        fontSize: "34px"
    }

    let teamStyle: SxProps<Theme> = {
        flexDirection: "column",
        padding: "10px 0 0 0",
        alignItems: "center",
        flexWrap: "nowrap",
        whiteSpace: "pre",
        fontWeight: "bold",
        width: "44%",
    }

    let logoStyle: SxProps<Theme> = {
        height: "73px",
        width: "110px",
    }

    let backgroundImageHome = roundFixture.homeTeam.logoUrl ? roundFixture.homeTeam.logoUrl :
        "https://cdn.sportmonks.com/images/soccer/team_placeholder.png";

    backgroundImageHome = `url(${backgroundImageHome})`;

    let backgroundImageAway = roundFixture.awayTeam.logoUrl ? roundFixture.awayTeam.logoUrl :
        "https://cdn.sportmonks.com/images/soccer/team_placeholder.png";

    backgroundImageAway = `url(${backgroundImageAway})`;

    let backGroundStyle: SxProps<Theme> = {
        width: "100%",
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    }

    let teamNameStyle: SxProps<Theme> = {
        fontSize: "14px",
        marginTop: "10px",
        fontWeight: 500
    }

    if (theme === themeC.Kung || theme === themeC.Dbet) {
        scoreStyle = { ...scoreStyle, ...{ fontSize: "50px", fontWeight: 500 } }
        logoStyle = { height: "73px", width: "100px" }
        backGroundStyle = { ...backGroundStyle, ...{ borderRadius: "10px" } }
    }

    const renderScoreAfterFullTime = (fixtureDetail: FixtureDetail) => {

        let { fixture } = fixtureDetail;

        if ((fixture.homeTeam.score !== fixture.homeTeam.eventBasedScore) ||
            (fixture.awayTeam.score !== fixture.awayTeam.eventBasedScore)) {

            if (fixture.homeTeam.eventBasedScore !== -1 && fixture.awayTeam.eventBasedScore !== -1) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontSize: '12px', marginBottom: '4px' }}>{t('after_extra_time')}</div>
                        {isRightToLeft
                            ? <Typography sx={scoreStyle}>{fixture.awayTeam.eventBasedScore} - {fixture.homeTeam.eventBasedScore}</Typography>
                            : <Typography sx={scoreStyle}>{fixture.homeTeam.eventBasedScore} - {fixture.awayTeam.eventBasedScore}</Typography>}
                    </div>
                );
            }
        }

        return <></>;
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ height: "243px", padding: "0 16px" }}>
            <Grid container direction="row" justifyContent="space-between" flexWrap="nowrap" sx={{ width: "70%", maxWidth: "600px", flexDirection: 'var(--flex-direction)' }}>
                <Grid container sx={teamStyle}>
                    {(theme === themeC.Kung || theme === themeC.Dbet || theme === themeC.Orange) && !roundFixture?.homeTeam?.isNationalTeam && !roundFixture?.awayTeam?.isNationalTeam ?
                        <Grid container sx={shirtContainerStyle}>
                            {KungShirtIcon(58, 60, home.color as string)}
                        </Grid> :
                        <Grid sx={logoStyle}>
                            <Box sx={{ ...{ background: backgroundImageHome, backgroundSize: roundFixture?.homeTeam?.isNationalTeam ? "cover" : "contain" }, ...backGroundStyle }} />
                        </Grid>
                    }
                    <Typography sx={teamNameStyle}>{roundFixture.homeTeam.name}</Typography>
                </Grid>

                <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ height: "130px" }}>
                    {isRightToLeft
                        ? <Typography sx={scoreStyle}>{roundFixture.awayTeam.score} - {roundFixture.homeTeam.score}</Typography>
                        : <Typography sx={scoreStyle}>{roundFixture.homeTeam.score} - {roundFixture.awayTeam.score}</Typography>}
                    {fixtureDetail && renderScoreAfterFullTime(fixtureDetail)}
                </Grid>

                <Grid container sx={teamStyle}>
                    {(theme === themeC.Kung || theme === themeC.Dbet || theme === themeC.Orange) && !roundFixture?.homeTeam?.isNationalTeam && !roundFixture?.awayTeam?.isNationalTeam ?
                        <Grid container sx={shirtContainerStyle}>
                            {KungShirtIcon(58, 60, away.color as string)}
                        </Grid> :
                        <Grid sx={logoStyle}>
                            <Box sx={{ ...{ background: backgroundImageAway, backgroundSize: roundFixture?.awayTeam?.isNationalTeam ? "cover" : "contain" }, ...backGroundStyle }} />
                        </Grid>
                    }
                    <Typography sx={teamNameStyle}>{roundFixture.awayTeam.name}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ScoreBoard;