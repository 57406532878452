import { Box, Grid, SxProps, Theme, Typography, useMediaQuery } from "@mui/material";
import { getRoundName } from "../common/RoundHelper";
import { Link } from 'react-router-dom';
import { PoolXNumberFormat, formatStringCurrency } from "../common/CurrencyFormatter";
import { IsRightToLeftTheme, themeC } from "../theme/themehelper";
import { BackIcon, ForwardArrowIcon, TicketIcon } from "../reusable-components";
import kungTicket from '../images/ticket_kung.png';
import { CouponShare } from "../couponShares/types";

function CouponShareListItem({ isActive, couponShare, t }: { isActive: boolean, couponShare: CouponShare, t: any }) {
    const { stake, createdAt, result, id, coupon, fee, agentFee } = couponShare;
    const { round } = coupon || {};
    const { openTo } = round || {};
    const { currency = '' } = stake || {};
    const createdAtDate = new Date(createdAt);
    const openToDate = new Date(openTo);
    const name = getRoundName(round, t);
    let locale = t('locale');
    let theme = window.theme;

    const isSmallScreen = useMediaQuery('(max-width: 620px)');

    let statusLabel = '';
    if (couponShare.status === 'CANCELED') {
        statusLabel = t('coupon_share_status_cancelled');
    }

    let isRightToLeft = IsRightToLeftTheme(window.theme);

    const nf = PoolXNumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const shortDate = Intl.DateTimeFormat(locale, { dateStyle: 'short' });
    const shortTime = Intl.DateTimeFormat(locale, { timeStyle: 'short' });

    let totAmount = 0;

    if (stake != null) {
        totAmount += Number(stake.amount)
    }

    if (fee != null) {
        totAmount += Number(fee.amount)
    }

    if (agentFee != null) {
        totAmount += Number(agentFee.amount)
    }

    const roundInfo: SxProps<Theme> = {
        fontWeight: 'bold',
        fontSize: '14px',
        color: 'var(--text-black-dark-background)'
    }

    const couponStyle: SxProps<Theme> = {
        flexDirection: "var(--flex-direction)"
    }

    const textStyle: SxProps<Theme> = {
        fontSize: '14px'
    }

    let backGround = "var(--list-boxes-secondary)";
    if (!isActive) {
        backGround = "var(--list-boxes-primary)";
    }

    let betIcon = `url(${kungTicket})`;

    let icon = TicketIcon;

    if (theme === themeC.Kung) {
        icon = <Box sx={{ backgroundImage: betIcon, width: "17px", height: "17px" }} />
    }

    return (
        <Link to={`/coupon/${id}`} style={{ textDecoration: 'none' }}>
            <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center"
                sx={{
                    backgroundColor: backGround,
                    marginTop: "18px",
                    padding: "15px"
                }} >
                {icon}
                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={couponStyle}>
                    <Grid sx={{minWidth:"100px"}}>
                        <Grid container direction='column' alignItems='var(--flex-align)'
                            sx={{ marginLeft: "15px" }}>
                            <Typography sx={roundInfo}>{name}</Typography>
                            <Typography sx={roundInfo}>{shortDate.format(openToDate)}</Typography>
                            <Typography sx={roundInfo}>{shortTime.format(openToDate)}</Typography>
                        </Grid>
                    </Grid>

                    {!isSmallScreen &&
                        <Grid sx={{minWidth:"100px"}}>
                            <Grid container direction='column' alignItems='var(--flex-align)'>
                                <Typography sx={textStyle}>{shortDate.format(createdAtDate)}</Typography>
                                <Typography sx={textStyle}>{shortTime.format(createdAtDate)}</Typography>
                            </Grid>
                        </Grid>
                    }

                    <Grid sx={{minWidth:"200px"}}>
                        <Typography sx={textStyle}>
                            {t('stake')}: {nf.format(totAmount)}
                        </Typography>

                        {result ? (
                            <Typography sx={textStyle}>{result !== null && result.amount > 0 ? `${formatStringCurrency(result.amount, currency, t)}` : t('no_profit')}</Typography>
                        ) : <Typography sx={textStyle}>{statusLabel}</Typography>}
                    </Grid>
                    {!isRightToLeft ? (ForwardArrowIcon("var(--text-black-dark-background)")) : (BackIcon("var(--text-black-dark-background)"))}
                </Grid>
            </Grid>
        </Link >
    );
}

export default CouponShareListItem;